










































































































































































// import { ElForm } from 'element-ui/types/form';
import { getQuestionnaireList, getItemById, getQuestionnaireId, addNewQestion, deleteQestion, updateQestion, addSelect,updateSelect,deleteSelect } from '@/serve/evaluatingBefore/evaluatingStandard';
import { Component, Vue } from 'vue-property-decorator';
interface DynamicTags {
    quesOptionContent: string;
    sort: string | number;
    quesOptionRelaVOList: QuesOptionRelaVOList[];
    id?: number | string;
}


interface QuesOptionRelaVOList {
    diseaseScore: number | string; //测评指数
    diseaseId: string | number; //疾病系统id
    id?: number;
}
interface AddData<T, U> {
    quesDesc: T;
    quesKey: T;
    quesName: T;
    quesType: T;
    id?: U;
    quesSubjectList: {
        quesSubjectContent: T;
        quesSubjectIsRequired: T;
        quesSubjectType: T;
        sort: T | U;
        id?: U;
        quesId?: U;
        quesOptionList: {
            quesOptionContent: T; //问卷选项内容
            sort: T | U; //排序
            quesOptionRelaVOList: {
                diseaseScore: T; //测评指数
                diseaseId: T | U; //疾病系统id
            }[];
        }[];
    }[];
}

interface TopicData<T, U> {
    check?: T | boolean;
    quesSubjectContent: T;
    quesSubjectIsRequired: T;
    quesSubjectType: T;
    sort: T | U;
    id?: U;
    quesId?: U;
    arr?: (T | U)[];
    item?: T | undefined;
    itemSort?: T | U | undefined;
    diseaseType?: T | undefined;
    scoreModel?: T | U | undefined;
    projectList?: QuesOptionRelaVOList[];

    quesOptionList: DynamicTags[];
    [propName: string]: any;
}
@Component
export default class MyComponent extends Vue {
    //是否显示添加题目弹窗
    public showAddTopic = false;
    public title = '满意度问卷';
    //题目类型
    public SelectType = [
        {
            content: '单选题',
            id: '0',
        },
        {
            content: '多选题',
            id: '1',
        },
        {
            content: '填空题',
            id: '2',
        }
    ]
    //题目关联结果
    public SelectResult = [
        {
            content: '满意',
            id: '0',
        },
        {
            content: '不满意',
            id: '1',
        }
    ];
    //新增问题数组
    public addQuestion = {
        //问题内容
        qtContent: "",
        //新增问题类型
        qtType: "",
        //问题排序
        qtSort: "",
    }
    //选择题选项新增数组
    public addSelectQuestion = {
        //问题id
        qtid: "",
        //选项内容
        qtAnswer: "",
        //选项关联结果
        qtResult: "",
        //选项排序
        qtSort: "",
    }
    //填空题选项新增数组
    public addFillQuestion = {
        //关键字
        qtKey: "",
        //关键字排序
        qtSort: "",
        //关键字关联结果
        qtResult: "",
    }
    //第一次获得的原始数据
    public tableData: TopicData<string, number>[] = [];
    //开始时调用
    //文章id
    public questionnaireId = 0
    public async created(): Promise<void> {
        console.log('created...');
        this.getDetailDiseaseApis();
        getQuestionnaireId({ type: '-2' }).then((res) => {
            this.questionnaireId = res.data.records[0].id;
        });
    }
    //获取满意度问卷详情
    public getDetailDiseaseApis() {
        getQuestionnaireList({ type: '-2' }).then(res => {
            this.tableData = res.data.records;
            getItemById(this.paramsTD).then(result => {
                const data = result.data.records;
                for (let i = 0; i < this.tableData.length; i++) {
                    if (this.tableData[i].type === '0' || this.tableData[i].type === '1') {
                        const content = [];
                        const answerIds = JSON.parse(this.tableData[i].answers)
                        if (answerIds.length === 0) {
                            this.tableData[i].answers = []
                            this.tableData[i].isShowEditUpdate = true
                            this.tableData[i].changebnt = true
                            this.tableData[i].sttitle = ''
                            this.tableData[i].stsort = ''
                            this.tableData[i].stRt = ''
                        }
                        for (let j = 0; j < answerIds.length; j++) {
                            for (let k = 0; k < data.length; k++) {
                                if (data[k].id === answerIds[j]) {
                                    const q = {
                                        id: data[k].id,
                                        content: data[k].content,
                                        sort: data[k].sort,
                                        derivedContent: data[k].derivedContent,
                                    }
                                    content.push(q);
                                    this.tableData[i].answers = content;
                                    this.tableData[i].isShowEditUpdate = true
                                    this.tableData[i].changebnt = true
                                    this.tableData[i].sttitle = ''
                                    this.tableData[i].stsort = ''
                                    this.tableData[i].stRt = ''
                                }
                            }
                        }
                    }else {
                        const answerIds = JSON.parse(this.tableData[i].answers)
                        this.tableData[i].answers = answerIds;
                        this.tableData[i].isShowEditUpdate = true
                        this.tableData[i].sttitle = ''
                        this.tableData[i].stsort = ''
                        this.tableData[i].stRt = ''
                    }
                }
            })
        });
    }
    // 查询答案列表，遍历出答案 getItemById
    public paramsTD = {
        size: 9999,//没有分页，固定死
        current: 1,
        keyword: '',
    }
    //关闭新增问题框
    public dialogShowClose() {
        this.showAddTopic = false;
        this.clearData();
    }

    //重置
    public clearData() {
        this.addQuestion = {
            //问题内容
            qtContent: "",
            //新增问题类型
            qtType: "",
            //问题排序
            qtSort: "",
        }
        this.addSelectQuestion = {
            qtid: "",
            //选项内容
            qtAnswer: "",
            //选项关联结果
            qtResult: "",
            //选项排序
            qtSort: "",
        }
        this.addFillQuestion = {
            //关键字
            qtKey: "",
            //关键字排序
            qtSort: "",
            //关键字关联结果
            qtResult: "",
        }
    }
    //点击删除按钮
    public deleteRow(questItem: any) {

        (this as any).$messageBox.confirm('是否删除该条数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            deleteQestion({ id: questItem.id }).then((res) => {
                console.log(res)
                this.$message.success('删除成功')
                this.getDetailDiseaseApis();
            })
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });

    }

    public showAdd = false;
    //判读单选多选和填空
    public showAnswer = true;
    //点击新增答案按钮
    public addAnswer(questItem: any) {
        this.showAdd = true
        this.showAnswer = true
        this.addSelectQuestion.qtid = questItem.id
    }
    //关闭新增问题框
    public adddialogclose() {
        this.showAdd = false
    }
    //新增问题
    public addQt() {
        const params = {
            questionnaireId: this.questionnaireId,
            title: this.addQuestion.qtContent.trim(),
            type: this.addQuestion.qtType,
            sort: this.addQuestion.qtSort,
            status: 'satisfy',
        }
        console.log('确认新增问题', params)
        addNewQestion(params).then(res => {
            if (res.code === 200) {
                this.$message.success('新增成功')
                this.dialogShowClose()
                this.getDetailDiseaseApis();
            } else {
                this.$message.error('新增失败')
            }
        })
    }

    //题目编辑按钮 
    public async editTopicUpdate(questionItem: TopicData<string, number>, index: number) {
        this.tableData[index].title = questionItem.title + ' '
        this.tableData[index].isShowEditUpdate = false
    }
    // 题目提交按钮
    public async postQuestionUpdate(questionItem: TopicData<string, number>, index: number) {
        this.tableData[index].isShowEditUpdate = true
        const params = {
            id: questionItem.id,
            title: questionItem.title,
            sort: questionItem.sort,
            type: questionItem.type,
            questionnaireId: this.questionnaireId,
            status: 'satisfy',
        }
        updateQestion(params).then((res) => {
            console.log('提交成功', res)
            if (res.code === 200) {
                this.$message.success('提交成功')
                this.clearData()
                this.getDetailDiseaseApis();
            } else {
                this.$message.error('提交失败')
            }
        })
    }

    //确认添加选项
    public adddialogSubmit() {
        console.log('添加选项', this.addSelectQuestion)
        const params = {
            questionnaireItemId: this.addSelectQuestion.qtid,
            content: this.addSelectQuestion.qtAnswer,
            derivedContent: JSON.stringify({ key: this.addSelectQuestion.qtResult })
        }
        console.log('添加选项参数', params)
        addSelect(params).then(res => {
            if (res.code === 200) {
                this.$message.success('添加成功')
                this.showAdd = false
                this.getDetailDiseaseApis();
                this.clearData()
            } else {
                this.$message.error('添加失败')
            }
        })
    }
    //点击标签修改选项
    public changTag(questionItem: any, index: any, item: any) {
        console.log('修改选项', questionItem, index, item)
        this.tableData[index].sttitle = item.content
        this.tableData[index].stSort = item.sort
        if (item.derivedContent !== "") {
            const key = JSON.parse(item.derivedContent)
            console.log('key', key)
            this.tableData[index].stRt = key.key
        } else {
            this.tableData[index].stRt = ''
        }
        this.tableData[index].changebnt = false
        for(let i = 0;i<this.tableData.length; i++){
            if (i !== index) {
                this.tableData[i].changebnt = true
            }
        }
        this.updateData = {
            //选项id
            id: item.id,
            //问题id
            questionnaireItemId: questionItem.id,
            //选项内容
            content: item.content,
            //选项排序
            sort: item.sort,
            //选项关联结果
            derivedContent: this.tableData[index].stRt ,
        }
        this.$forceUpdate()
    }
    //存储修改数据和修改框状态
    //是否打开修改框
    public showUpdate = false
    //存储修改对象
    public updateData = {
        //选项id
        id: 0,
        //问题id
        questionnaireItemId: '',
        //选项内容
        content: '',
        //选项排序
        sort: 0,
        //选项关联结果
        derivedContent: '',
    }
    //关闭更新框
    public closeUpdate(){
        this.showUpdate = false
        this.updateData = {
        //选项id
        id: 0,
        //问题id
        questionnaireItemId: '',
        //选项内容
        content: '',
        //选项排序
        sort: 0,
        //选项关联结果
        derivedContent: '',
    }
    }
    //更新方法
    public async updateSubmit() {
        this.updateData.derivedContent=JSON.stringify({ key: this.updateData.derivedContent })
        updateSelect(this.updateData).then(res => {
            if(res.code === 200){
                this.$message.success('修改成功')
                this.getDetailDiseaseApis();
                this.closeUpdate()
            }else{
                this.$message.error('修改失败')
            }
        })
    }
    //删除方法
    public async delSelect() {
        (this as any).$messageBox.confirm('是否删除该选项?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            deleteSelect({id: this.updateData.id}).then(res => {
            if (res.code === 200) {
                this.$message.success('删除成功')
                this.getDetailDiseaseApis();
            } else {
                this.$message.error('删除失败')
            }
        })   
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        });
    }
}
